<template>
	<div>
		<div class="mode-center">
			<div
				class="anime-hover w-full text-pine pt-170 pb-32 mx900:grid-cols-1 mx640:pt-140 mx640:pb-100 grid grid-cols-5 gap-24 mx900:gap-0"
			>
				<div class="col-span-3">
					<h1
						v-if="data_community_intro.title"
						class="h1-text mb-8 mx640:text-9 mx1080:text-6"
					>
						{{ data_community_intro.title }}
					</h1>
					<div
						v-if="data_community_intro.text"
						v-html="data_community_intro.text"
						class="font-tiempos_r section_text section_text_bread anime-hover rich-text"
					></div>
				</div>
				<div class="col-span-2" v-if="data_community_intro.image">
					<img class="w-full" :src="data_community_intro.image.md" />
				</div>
				<div class="col-span-2" v-if="data_community_intro.image == null">
					<img
						class="mt-120 mx900:mt-0 mx640:w-1/2 mx900:w-9/12 mx640:mx-auto"
						src="@/assets/logo-huge.svg"
						alt=""
					/>
				</div>
			</div>
			<div
				class="w-full flex justify-between text-pine text-24 mx1280:text-2 pb-100 font-tiempos_r mx900:flex-wrap mx900:text-3 mx640:text-4.5"
				v-if="hero_list_title_1"
			>
				<div
					class="w-30% text-center mx900:w-45% mx900:mb-16 mx640:w-full"
					v-if="hero_list_title_1"
				>
					<div class="w-full pb-12">
						<img
							class="w-45% my-0 mx-auto"
							src="@/assets/community/1.svg"
							alt=""
						/>
					</div>
					<p>{{ hero_list_title_1 }}</p>
				</div>
				<div
					class="w-30% text-center mx900:w-45% mx900:mb-16 mx640:w-full"
					v-if="hero_list_title_2"
				>
					<div class="w-full pb-12">
						<img
							class="w-45% my-0 mx-auto"
							src="@/assets/community/2.svg"
							alt=""
						/>
					</div>
					<p>{{ hero_list_title_2 }}</p>
				</div>
				<div
					class="w-30% text-center mx900:w-45% mx900:mb-16 mx640:w-full"
					v-if="hero_list_title_3"
				>
					<div class="w-full pb-12">
						<img
							class="w-45% my-0 mx-auto"
							src="@/assets/community/3.svg"
							alt=""
						/>
					</div>
					<p>{{ hero_list_title_3 }}</p>
				</div>
			</div>
		</div>
		<div class="bg-pine">
			<div class="anime-hover-mandarin mode-center text-white pb-10">
				<h1 class="pt-120 pb-24 font-national text-65 mx640:text-9">
					{{ data_community_list.title }}
				</h1>
				<div
					class="grid grid-cols-8 gap-4 pb-100 font-tiempos_r w-11/12 mx900:w-full"
					v-for="item in data_community_list.list"
					:key="item.key"
				>
					<div
						class="col-span-2 col-start-2 text-30 mx1280:text-3.5 mx640:col-span-7 mx640:text-6 leading-tight mx900:col-start-1"
					>
						{{ item.title }}
					</div>
					<div
						class="col-span-4 col-start-5 text-22 mx1280:text-2 mx900:text-2.5 mx640:col-span-8 mx640:pt-10 mx640:text-4"
					>
						<div v-html="item.description" class="mb-6 rich-text"></div>
					</div>
				</div>
			</div>
		</div>
		<GetInTouch />
	</div>
</template>
<script>
	import { EventBus } from "@/modules/event_bus";
	import { globals } from "@/modules/globals";
	import data from "@/data.json";
	import GetInTouch from "../components/ui/GetInTouch.vue";
	export default {
		name: "Community",
		components: { GetInTouch },
		data() {
			return {
				tempData: data,
				data_community_intro: {},
				data_community_list: {},
				hero_list_title_1: null,
				hero_list_title_2: null,
				hero_list_title_3: null,
			};
		},
		beforeRouteEnter(to, from, next) {
			let json_file_sections =
				"/json/sections/sections_community_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_sections)
				.then((response) => response.json())
				.then(function(data) {
					next((vm) => vm.setSectionsData(data));
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});
		},
		mounted() {},
		methods: {
			setSectionsData(data) {
				this.data_community_intro = data["community-intro"];
				this.data_community_list = data["community-list"];

				if (this.data_community_list.list[0].title) {
					this.hero_list_title_1 = this.data_community_list.list[0].title;
				}
				if (this.data_community_list.list[1].title) {
					this.hero_list_title_2 = this.data_community_list.list[1].title;
				}
				if (this.data_community_list.list[2].title) {
					this.hero_list_title_3 = this.data_community_list.list[2].title;
				}
			},
		},
	};
</script>

<style></style>
